<template>
  <div class="relative" :class="[{ 'w-full': !customWidth }]">
    <label
      v-if="!hasIcon && (label || optional || invalid)"
      class="flex justify-between text-sm font-semibold text-gray-800"
      :class="[{ 'text-red-600': invalid && !optional }, { 'mb-2': !invalid }]"
      :for="id"
    >
      <span class="text-sm text-gray-800">{{ label }}</span>
      <span v-if="optional" class="text-small text-gray-600 font-light"> Optional </span>
      <span
        v-if="invalid && !optional"
        class="absolute -top-6 right-0 text-small text-red-600 font-light"
      >
        {{ errorMessage ? errorMessage : 'Required field' }}
      </span>
    </label>
    <label v-else-if="hasIcon">
      <span
        v-if="invalid && !optional"
        class="absolute -top-6 right-0 text-small text-red-600 font-light"
      >
        {{ errorMessage ? errorMessage : 'Required field' }}
      </span>
    </label>

    <TheMask
      v-if="mask"
      :mask="mask"
      autocomplete="disabled"
      autocorrect="false"
      spellcheck="false"
      @input="updateValueMask()"
      @focus="
        isFocused = true;
        $emit('focused', isFocused);
      "
      @blur="
        isFocused = false;
        $emit('blur');
        $emit('focused', isFocused);
      "
      v-on:keyup.enter="$emit('onKeyEnter')"
      :id="id"
      :name="name"
      :type="type"
      ref="input"
      class="input w-full text-gray-900 bg-white rounded shadow border border-gray-300 text-base placeholder-gray-500 focus:outline-none transition-colors duration-200 ease"
      :class="[
        { 'input--filled': value && value.length > 0 },
        { 'border-red-600': invalid && !optional },
        { disabled: disabled },
        { 'py-3 pl-14 pr-5': hasIcon && !showLabel && label },
        { 'pr-140px sm:pr-45 search': isSearch },
        { 'pt-7 pb-3 pl-12 pr-4': hasIcon && showLabel && label },
        { 'py-4 px-5': !hasIcon && label },
        { 'py-3 px-5': !label },
        { 'h-56px': !customHeight },
      ]"
      :value="value"
      :placeholder="placeholder || (isFocused && focusedPlaceholder) || label"
      :maxlength="maxLength"
      :disabled="disabled"
    />

    <input
      v-else
      autocomplete="disabled"
      autocorrect="false"
      spellcheck="false"
      @input="updateValue()"
      @focus="
        isFocused = true;
        $emit('focus');
        $emit('focused', isFocused);
      "
      @blur="
        isFocused = false;
        $emit('blur');
        $emit('focused', isFocused);
      "
      v-on:keyup.enter="$emit('onKeyEnter')"
      :id="id"
      :name="name"
      :type="type"
      ref="input"
      class="input w-full text-gray-900 text-sm bg-white rounded shadow border border-gray-300 hover:border-gray-800 placeholder-gray-600 focus:outline-none transition-colors duration-200 ease"
      :class="[
        customHeight,
        { 'input--filled': value && value.length > 0 },
        { 'border-red-600': invalid && !optional },
        { disabled: disabled },
        { 'py-3 pl-12 pr-5': hasIcon && !showLabel && label },
        { 'pr-140px sm:pr-45 search': isSearch && searchIncludeMyList },
        { 'pt-7 pb-3 pl-12 pr-4': hasIcon && showLabel && label },
        { 'py-4 px-5': !hasIcon && label },
        { 'py-3 px-5': !label },
        { 'h-[56px]': !customHeight },
      ]"
      :value="value"
      :placeholder="placeholder || (isFocused && focusedPlaceholder) || label"
      :maxlength="maxLength"
      :disabled="disabled"
    />

    <CloseIcon
      @click="clearValue"
      class="close-icon absolute w-4 cursor-pointer top-1/3"
      :class="[
        { 'right-120 sm:right-152': searchIncludeMyList },
        { 'right-4': !searchIncludeMyList },
      ]"
      v-if="hasClearIcon && value.length"
    ></CloseIcon>

    <template v-if="label">
      <label
        v-if="hasIcon && showLabel"
        class="absolute left-0 text-gray-600 mr-4 ml-12 pointer-events-none mt-2 origin-top-left transition duration-200 ease-out"
        :class="[{ 'text-red-600': invalid && !optional }]"
        :for="id"
      >
        {{ label }}
      </label>
      <span
        v-if="hasIcon"
        class="icon-wrapper absolute top-0 left-0 h-full flex items-center ml-4 text-gray-400 transition-colors duration-200 ease-out pointer-events-none sm:px-1"
      >
        <label :for="id" class="pointer-events-none opacity-0 w-0 h-0">{{ label }}</label>
        <slot name="icon"></slot>
      </span>
    </template>
  </div>
</template>

<script>
import { TheMask } from 'vue-the-mask';
import CloseIcon from '@/assets/icon_close.svg';

export default {
  name: 'TextInput',
  components: {
    CloseIcon,
    TheMask,
  },
  data() {
    return {
      isFocused: false,
    };
  },
  props: {
    /**
     * id of the input
     */
    id: {
      type: String,
    },
    /**
     * name of the input
     */
    name: {
      type: String,
    },
    /**
     * Placeholder text inside the input
     */
    placeholder: {
      type: String,
      default: '',
    },
    /**
     * Placeholder text inside the input when input is focused
     */
    focusedPlaceholder: {
      type: String,
      default: '',
    },
    /**
     * Input type. Can be changed to password or email, for instance
     */
    type: {
      type: String,
      default: 'text',
    },
    /**
     * The value for the text input
     */
    value: {
      type: String,
    },
    /**
     * Emit focus state
     */
    focused: {
      type: Function,
    },
    /**
     * The text that will be displayed for the Label
     */
    label: {
      type: String,
    },
    /**
     * Input is optional and can't be invalid
     */
    optional: {
      type: Boolean,
      default: false,
    },
    /**
     * Determines whether a field is invalid and should display differently (e.g. a red border)
     */
    invalid: {
      type: Boolean,
      default: false,
    },
    /**
     * Show default error message or custom
     */
    errorMessage: {
      type: String,
      default: '',
    },
    /**
     * The max number of characters of the input field
     */
    maxLength: {
      type: Number,
      default: 524288, // The HTML spec default value
    },
    /**
     * Determines if the field is disabled or not
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Flag specifically for text inputs with icons that should show the label too
     */
    showLabel: {
      type: Boolean,
      default: false,
    },
    /**
     * removes full width styling if true
     */
    customWidth: {
      type: Boolean,
      default: false,
    },
    /**
     * removes default height styling if true
     */
    customHeight: {
      type: String,
      default: '',
    },
    hasClearIcon: {
      type: Boolean,
      default: false,
    },
    isSearch: {
      type: Boolean,
      default: false,
    },
    searchIncludeMyList: {
      type: Boolean,
      default: false,
    },
    onKeyEnter: {
      type: Function,
    },
    mask: {
      type: String,
      default: null,
    },
  },
  computed: {
    hasIcon() {
      return this.$slots.icon;
    },
  },
  methods: {
    updateValue() {
      if (this.$refs.input) {
        this.$emit('input', this.$refs.input.value);
      }
    },
    updateValueMask() {
      if (this.$refs.input) {
        this.$emit('input', this.$refs.input.lastValue);
      }
    },
    clearValue() {
      this.value = '';
      this.$emit('input', '');
    },
  },
};
</script>

<style lang="scss" scoped>
.disabled {
  @apply bg-gray-200 shadow-none text-gray-700;
}

.input.search {
  padding-left: 48px;

  &:focus {
    box-shadow: 0 0 0 2px #c8cacd;
  }
}

.close-icon path {
  stroke: #aaacaf;
}
</style>
