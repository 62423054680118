var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"relative",class:[{ 'w-full': !_vm.customWidth }]},[(!_vm.hasIcon && (_vm.label || _vm.optional || _vm.invalid))?_c('label',{staticClass:"flex justify-between text-sm font-semibold text-gray-800",class:[{ 'text-red-600': _vm.invalid && !_vm.optional }, { 'mb-2': !_vm.invalid }],attrs:{"for":_vm.id}},[_c('span',{staticClass:"text-sm text-gray-800"},[_vm._v(_vm._s(_vm.label))]),(_vm.optional)?_c('span',{staticClass:"text-small text-gray-600 font-light"},[_vm._v(" Optional ")]):_vm._e(),(_vm.invalid && !_vm.optional)?_c('span',{staticClass:"absolute -top-6 right-0 text-small text-red-600 font-light"},[_vm._v(" "+_vm._s(_vm.errorMessage ? _vm.errorMessage : 'Required field')+" ")]):_vm._e()]):(_vm.hasIcon)?_c('label',[(_vm.invalid && !_vm.optional)?_c('span',{staticClass:"absolute -top-6 right-0 text-small text-red-600 font-light"},[_vm._v(" "+_vm._s(_vm.errorMessage ? _vm.errorMessage : 'Required field')+" ")]):_vm._e()]):_vm._e(),(_vm.mask)?_c('TheMask',{ref:"input",staticClass:"input w-full text-gray-900 bg-white rounded shadow border border-gray-300 text-base placeholder-gray-500 focus:outline-none transition-colors duration-200 ease",class:[
      { 'input--filled': _vm.value && _vm.value.length > 0 },
      { 'border-red-600': _vm.invalid && !_vm.optional },
      { disabled: _vm.disabled },
      { 'py-3 pl-14 pr-5': _vm.hasIcon && !_vm.showLabel && _vm.label },
      { 'pr-140px sm:pr-45 search': _vm.isSearch },
      { 'pt-7 pb-3 pl-12 pr-4': _vm.hasIcon && _vm.showLabel && _vm.label },
      { 'py-4 px-5': !_vm.hasIcon && _vm.label },
      { 'py-3 px-5': !_vm.label },
      { 'h-56px': !_vm.customHeight },
    ],attrs:{"mask":_vm.mask,"autocomplete":"disabled","autocorrect":"false","spellcheck":"false","id":_vm.id,"name":_vm.name,"type":_vm.type,"value":_vm.value,"placeholder":_vm.placeholder || (_vm.isFocused && _vm.focusedPlaceholder) || _vm.label,"maxlength":_vm.maxLength,"disabled":_vm.disabled},on:{"input":function($event){return _vm.updateValueMask()},"focus":function($event){_vm.isFocused = true;
      _vm.$emit('focused', _vm.isFocused);},"blur":function($event){_vm.isFocused = false;
      _vm.$emit('blur');
      _vm.$emit('focused', _vm.isFocused);},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('onKeyEnter')}}}):_c('input',{ref:"input",staticClass:"input w-full text-gray-900 text-sm bg-white rounded shadow border border-gray-300 hover:border-gray-800 placeholder-gray-600 focus:outline-none transition-colors duration-200 ease",class:[
      _vm.customHeight,
      { 'input--filled': _vm.value && _vm.value.length > 0 },
      { 'border-red-600': _vm.invalid && !_vm.optional },
      { disabled: _vm.disabled },
      { 'py-3 pl-12 pr-5': _vm.hasIcon && !_vm.showLabel && _vm.label },
      { 'pr-140px sm:pr-45 search': _vm.isSearch && _vm.searchIncludeMyList },
      { 'pt-7 pb-3 pl-12 pr-4': _vm.hasIcon && _vm.showLabel && _vm.label },
      { 'py-4 px-5': !_vm.hasIcon && _vm.label },
      { 'py-3 px-5': !_vm.label },
      { 'h-[56px]': !_vm.customHeight },
    ],attrs:{"autocomplete":"disabled","autocorrect":"false","spellcheck":"false","id":_vm.id,"name":_vm.name,"type":_vm.type,"placeholder":_vm.placeholder || (_vm.isFocused && _vm.focusedPlaceholder) || _vm.label,"maxlength":_vm.maxLength,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.updateValue()},"focus":function($event){_vm.isFocused = true;
      _vm.$emit('focus');
      _vm.$emit('focused', _vm.isFocused);},"blur":function($event){_vm.isFocused = false;
      _vm.$emit('blur');
      _vm.$emit('focused', _vm.isFocused);},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$emit('onKeyEnter')}}}),(_vm.hasClearIcon && _vm.value.length)?_c('CloseIcon',{staticClass:"close-icon absolute w-4 cursor-pointer top-1/3",class:[
      { 'right-120 sm:right-152': _vm.searchIncludeMyList },
      { 'right-4': !_vm.searchIncludeMyList },
    ],on:{"click":_vm.clearValue}}):_vm._e(),(_vm.label)?[(_vm.hasIcon && _vm.showLabel)?_c('label',{staticClass:"absolute left-0 text-gray-600 mr-4 ml-12 pointer-events-none mt-2 origin-top-left transition duration-200 ease-out",class:[{ 'text-red-600': _vm.invalid && !_vm.optional }],attrs:{"for":_vm.id}},[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.hasIcon)?_c('span',{staticClass:"icon-wrapper absolute top-0 left-0 h-full flex items-center ml-4 text-gray-400 transition-colors duration-200 ease-out pointer-events-none sm:px-1"},[_c('label',{staticClass:"pointer-events-none opacity-0 w-0 h-0",attrs:{"for":_vm.id}},[_vm._v(_vm._s(_vm.label))]),_vm._t("icon")],2):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }