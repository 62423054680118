<template>
  <div class="w-full px-4 my-8 sm:px-8 md:w-1/2 md:mx-auto lg:w-1/3">
    <h1 class="font-serif text-primary text-2xl pb-4">Welcome back!</h1>
    <div class="flex mb-4">
      <TextInput
        label="Email"
        type="email"
        id="email"
        v-model="potentialUser.email"
        :invalid="!!authenticationError"
        @keyup.enter.native="onLogin(potentialUser)"
      />
    </div>
    <div class="flex mb-4">
    <TextInput
      label="Password"
      type="password"
      id="password"
      v-model="potentialUser.password"
      :invalid="!!authenticationError"
      @keyup.enter.native="onLogin(potentialUser)"
    />
    </div>
    <router-link
      key="forgot-password"
      class="inline-block font-semibold leading-relaxed relative text-left
        appearance-none rounded cursor-pointer transform underline text-primary"
      :to="{ name: 'forgot-password' }">
      <span>Forgot your password?</span>
    </router-link>
    <template v-if="globalErrors && globalErrors.length">
      <ErrorBox
        class="my-4"
        v-for="(error, index) in globalErrors"
        :message="error"
        :key="`error${index}`"
      />
    </template>
    <div :class="{ 'mt-8': globalErrors && !globalErrors.length }">
      <Button
        type="primary"
        @onClick="onLogin(potentialUser)"
        :disabled="emptyInputs() || isLoading"
        :loading="isLoading"
      >
        Submit
      </Button>
    </div>
  </div>
</template>
<script>
import Button from '@/components/Button.vue';
import TextInput from '@/components/TextInput.vue';
import ErrorBox from '@/components/ErrorBox.vue';
import { mapActions, mapGetters } from 'vuex';

export default {
  name: 'Login',
  components: {
    ErrorBox,
    TextInput,
    Button,
  },
  computed: {
    ...mapGetters([
      'authenticationError',
      'isLoading',
      'isAuthenticated',
      'user',
      'globalErrors',
    ]),
  },
  data() {
    return {
      potentialUser: {
        email: '',
        password: '',
      },
    };
  },
  created() {
    this.clearGlobalErrors();
    this.updateLinkSentStatus(false);
  },
  methods: {
    ...mapActions([
      'login',
      'showAuthError',
      'clearAuthError',
      'updateLinkSentStatus',
      'fetchCurrentUser',
      'clearGlobalErrors',
    ]),
    redirect() {
      // require user to finish setting up profile if incomplete
      if (!this.user.profilePicture) {
        this.$router.push({
          name: 'signup',
          params: {
            group: this.user.groups[0].slug,
          },
        });
        return;
      }
      if (this.$route.query && this.$route.query.redirectUrl) {
        this.$router.push(this.$route.query.redirectUrl);
        return;
      }
      this.$router.push({
        name: 'groups',
      });
    },
    onLogin(potentialUser) {
      if (!potentialUser.email.trim() || !potentialUser.password.trim()) {
        this.showAuthError('Please enter a valid email and password to login.');
      }
      return this.login(potentialUser).then((response) => {
        if (response.success) {
          this.fetchCurrentUser().then(() => {
            this.redirect();
          });
        }
      });
    },
    emptyInputs() {
      return !this.potentialUser.email.trim() || !this.potentialUser.password.trim();
    },
  },
};
</script>
